// progress {
// 	display: none;
// 	visibility: hidden;
//     appearance: none;
// }

// progress,
// .progress__container{
//     --progress-raduis: 999px;
//     width: 100%;
// 	height: 1rem;
// 	margin: .5rem auto;
// 	overflow: hidden;
// 	background: var(--primary-100, #eee);
// 	border-radius: var(--progress-raduis, 0);
// }

.progress__container{
	position: relative;
	display: flex;
	width: 100%;
}

progress{
	height: .8rem;
	width: 100%;
	appearance: none;
	background: var(--primary-100, #eee);
	&::-moz-progress-bar,
	&::-webkit-progress-value{
		background: var(--primary-500, #007bff);
	}
	border-radius: 50px;
	border-color: transparent;

	&.progress--success{
		background: var(--success-100, #d7f6eb);
		&::-moz-progress-bar,
		&::-webkit-progress-value{
			background: var(--success-500, #36D399);
		}
	}
}
// .progress__container::before {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: 100%;
// 	padding: .5rem;
// 	background: var(--primary-400, #dadada);
// 	border-radius: var(--progress-raduis, 0);
// 	content: '';
// }

// .progress--show-percent .progress__container::after {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 100%;
// 	height: 100%;
// 	padding: .5rem;
// 	color: var(--primary-900, #000);
// 	font-size: .7rem;
// 	content: attr(data-value) '%';
// }
