.sortableJs__item {
    // @apply transition-all;
    // transition-duration: 300ms;
    // @extend .cards;
    @extend %rounded;
    border-style: solid;
    @extend .cards-light;
    @extend .cards-outline;
    justify-content: space-between;
    flex-shrink: 0;

    .cards__title{
        position: relative;
        @apply truncate text-xs;
        padding: .6rem .4rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        h3,
        .js-head{
            user-select: none;
        }

        .js-url-container{
            position: absolute;
            display: flex;
            align-items: flex-start;
            font-size: .6rem;
            top: 55%;
            left: 55px;
            right: 30%;
            min-height: 25px;
            min-width: 150px;
            // @media screen and (min-width: 768px){
            //     min-width: 250px;
            // }
            span.js-url{
                &.is-external{
                    &::before{
                        // content: "(EXT)";
                    }
                }
                &::before{
                    // content: '/';
                }
                &.italic{
                    opacity: .5;
                }
            }
            // span.js-url.is-external ~ span.js-url-tag{
            //     opacity: ;
            // }
            // span.js-url-tag{
            //     opacity: .5;
            // }
        }
        
    }

    .cards__container{
        position: relative;
        z-index: 2;
        @apply transition-all;
        transition-duration: 300ms;
        display: flex;
        flex-direction: column;
        // overflow: auto;
        gap: 1rem;
        min-height: calc(3rem + 45px);
        padding-top: 1.5rem !important;
        padding-bottom: calc(1.5rem + 45px);
    }

    &.sortableJs__item-xs{
        .cards__title{
            padding: .4rem .3rem;
        }
    }
}
.actions button{
    &,
    & > *{
        cursor: pointer;
    }
}

#pagesList{
    .sortableJs__item{
        .cards__container{
            display: none;
        }
        .actions{
            display: none;
        }
        .nested-sortable-placeholder{
            display: none;
        }
        &:hover,&:focus,&.is-active{
            border-color: $light-4;
            & > .cards__title{
                background-color: $light-4;
            }
        }
    }
}

#menuItemsList{
    position: relative;
    padding-bottom: 3rem;
    z-index: 2;
    .sortableJs__item{
        margin-bottom: 1rem;
        border-color: $light-3;
        & > .cards__title{
            background-color: $light-3;
        }
        
        &.is-minimized{
            .cards__container{
                display: none;
            }
            & > .cards__title > .actions > .js-minimize{
                display: none;
            }
        }

        &:not(.is-minimized){
            & > .cards__title > .actions > .js-expand{
                display: none;
            }
        }
    }
}

.nested-sortable-placeholder{
    position: absolute;
    z-index: -1;
    bottom: 1.5rem;
    left: 1rem;
    right: 1rem;
    height: 45px;
    @extend .cards;
    @extend .cards-light;
    @extend .cards-outline; 
    @apply text-center text-xs;
    width: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    color: $light-8;

    border-style: dashed;
    border-color: $light-4;
    border-width: 2px;
    background-color: transparent;
}

// Is ghost \ chosen \ fallback
.sortableJs__item {
    &.is-ghost,
    &.is-chosen{
        // opacity: .85;
        border-color: $light-6 !important;
        & > .cards__title{
            background-color: $light-6 !important;
        }
        .actions{
            display: none;
        }
        .cards__container{
            display: none;
            min-height: 0 !important;
            max-height: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .nested-sortable-placeholder{
            display: none;
        }
    }
}
.sortableJs__item.is-fallback{
    opacity: .8;
}

// Max Depth Level 4
.sortableJs__item>.nested-sortable>.sortableJs__item>.nested-sortable>.sortableJs__item>.nested-sortable>.sortableJs__item>.nested-sortable {
    display: none;
    .nested-sortable-placeholder{
        display: none;
    }
}


// Preview
#menuPreview{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    .sortableJs__item{
        cursor: pointer;
        border-color: $light-2;
        .cards__title{
            background-color: $light-2;
            padding: .5rem;
            align-items: center;
            height: 100%;
            h3{
                @apply text-xs;
            }
        }
        .cards__container{
            display: none;
        }
    }
}



