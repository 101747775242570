.ql-toolbar{
    background-color: $light;
    border-color:$light !important;
    border-top-right-radius: $rounded;
    border-top-left-radius: $rounded;

    button{
        color: $dark;
        &:hover{
            color: $primary !important;
        }
    }
}

.ql-container{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color:$light !important;
    font-family: var(--font-family1) !important;
}

em{
    mix-blend-mode: normal !important;
}