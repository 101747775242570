input[type="text"].field,
input[type="number"].field,
input[type="email"].field,
input[type="tel"].field,
input[type="url"].field,
input[type="password"].field,
input[type="search"].field,
input[type="date"].field,
input[type="file"].field,
textarea.field,
select.field,
select.field__select,
.field__select,
.field__textarea{
    min-height: 45px;
    border: 1px solid $light;
    @extend %rounded;
    background-color: white;
    color: $dark;
    outline-color: transparent;
    &:focus,
    &:focus-visible{
        border-style: solid;
        border-color: $light-8;
        outline-offset: -1px;
    }
    &:read-only:not(select)
    {
        background-color: $light-4;
    }
    &:disabled{
        background-color: $light;
    }
}
input.datepicker{
    &:read-only{
        background-color: white !important;
    }
}

.field__select.js-select{
    &:read-only{
        background-color: white;
    }
}

select{
    padding-left: .8rem;
    padding-right: .8rem;
}

.field__select.ss-content{
    padding: 0;
}

textarea,
.field__textarea{
    border-style: solid !important;
    background-color: white !important;
    &:disabled{
        background-color: $light !important;
    }
}

input[type="checkbox"],
input[type="radio"]{
    outline: none !important;
    &:disabled{
        // background-color: $light !important;
        & + label,
        & + .field__label{
            opacity: .8;
            // font-style: italic;
        }
    }
}

.field{
    &__group{
        display: flex;
        align-items: stretch;
        gap: $gap;
    }
    &__row{
        margin-bottom: 0;
    }
    &__toggle{
        &--icon{
            position: relative;
            height: 1.1rem;
            width: 2rem;
            &::after{
                height: calc(1.1rem - 6px);
                width:  calc(1.1rem - 6px);
                top: 3px;
                left: 3px;
            }
        }

        &:checked{
            & ~ .field__toggle--icon{
                &::after{
                    transform: none !important;
                    // 100% -> Size container 
                    // 1.1rem - 6px -> Size icon
                    // 3px -> Position icon
                    left: calc(100% - (1.1rem - 6px) - 3px);
                }
            }
        }
    }
    &--icon{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        padding: .5rem;
        color: $primary;
        @extend %flexCenterCenter;
        &.right-0{
            left: unset;
        }
    }
    &__container{
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        position: relative;
    }
}
// input[type="checkbox"]{
//     border
// }

