.sidebar__header{
    position: fixed;
    z-index: 100;
    @extend %transition;
    transform: translateX(-100%);
    width: 275px;
    padding: 0;
    @media screen and (min-width: 1024px){
        position: sticky;
        display: flex!important;
        transform: translateX(0);
        width: 300px;
    }
    top: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__container{
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        @extend %flexCenterCenter;
        flex-direction: column;
        padding: 0;
        flex-wrap: nowrap;
        max-height: 100%;
    }

    .lists--default{
        /* Pour Chrome, Edge, et Safari */
        ::-webkit-scrollbar {
            width: 12px; /* Largeur de la barre de défilement */
        }

        ::-webkit-scrollbar-thumb {
            background-color: $primary-4; /* Couleur de la poignée de la barre de défilement */
            border-radius: 6px; /* Coins arrondis de la poignée */
        }

        ::-webkit-scrollbar-track {
            background-color: #f0f0f000; /* Couleur de la piste de la barre de défilement */
        }

        /* Pour Firefox */
        /* Remarque : la personnalisation de la barre de défilement n'est pas prise en charge dans Firefox par les propriétés CSS, vous pouvez uniquement changer la couleur */
        /* La personnalisation plus avancée de la barre de défilement est possible via des extensions Firefox telles que "Simple Scroll Bar X" */

        /* Pour IE et Edge (versions plus anciennes) */
        /* Remarque : la personnalisation de la barre de défilement n'est pas prise en charge dans les versions plus anciennes d'IE et Edge sans utiliser des hacks JavaScript, ce qui peut être compliqué */

        /* Pour les autres navigateurs, vous pouvez spécifier une apparence générale de secours */
        scrollbar-width: thin;
        scrollbar-color: $primary-4 #f0f0f000;

    }

    .links{
        z-index: 1;
        width: 100%;
        padding: .8rem;
        border-radius: $rounded;
        position: relative;
        background-color: transparent !important;
        &::before{
            z-index: 1;
            left: -.5rem;
            position: absolute;
            top: -2px;
            bottom: -2px;
            // height: 100%;
            width: 6px;
            border-top-right-radius: $rounded;
            border-bottom-right-radius: $rounded;
        }
        &::after{
            z-index: -1;
            inset: 0;
            left: -.5rem;
            position: absolute;
            opacity: .5;
            border-radius: $rounded;
        }
        &:hover,&:focus,&.is-active{
            &::before,
            &::after{
                content: '';
            }
        }

        &.bg-primary-main{
            &::after,
            &::before{
                background-color: $primary-3;
            }
        }
        &.bg-secondary-main{
            &::after,
            &::before{
                background-color: $secondary-4;
            }
        }
        &.bg-tertiary-main{
            &::after,
            &::before{
                background-color: $tertiary-4;
            }
        }
        &.bg-light-main{
            &::after,
            &::before{
                background-color: $light-4;
            }
        }
    }

    .dropdown{
        &__content{
            // height: 0;
            // display: flex !important;
            width: 100% !important;
            position: relative !important;
            overflow: hidden;
            top: unset !important;
            left: unset !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: $primary;
            box-shadow: none;
            .links{
                font-size: .9rem;
                &::before{
                    display: none !important;
                    content: "";
                }
                &::after{
                    left: 0 !important;
                }
            }
        }
        .dropdown__name,
        .hs-dropdown-toggle
        {
            &::after,
            &::before{
                display: none;
            }
            &:hover{
                &::after,
                &::before{
                    display: inline;
                } 
            }
        }
        &.is-open,
        &.open{
            .dropdown__name,
            .hs-dropdown-toggle
            {
                transition-delay: 0;
                &::after,
                &::before{
                    display: inline;
                    content: "";
                }
                
            }
        }
    }


    &.open{
        transform: translateX(0);
    }
}