.pagination{
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    &__info{

    }
    &__list{
        display: flex;
        align-items: center;
        gap: .1rem;
        .page,.page-link, button {
            padding: .5rem;
        }
    }


    &__item.active{
        .page,.page-link, button {
            color: $primary;
        }
    }
    .page,.page-link, button {
        &[aria-current="page"]{
            color: $primary;
        }
        &:hover{
            color: $primary;
        }
    }
}