.searchBar{
    @apply shadow-sm lg:shadow-none bg-white lg:bg-transparent top-0 sticky lg:relative;
    z-index: 50;
    @media screen and (min-width: 1024px){
        top: unset;
    }
    &__container{
        display: flex;
        @extend %rounded;
        @apply w-full gap-4 py-4 px-2 lg:px-8 lg:pb-0 justify-between lg:justify-between items-center;
    }
}