.tabs{
    flex-wrap: nowrap;
    overflow: auto;
    &__item{
        border-top-right-radius: $rounded;
        border-top-left-radius: $rounded;

        &:disabled{
            background-color: $light !important;
        }
    }
}