.links{
    &-white{
        @extend .links;
        color: white;
        &:hover,&:focus,&.is-active{
            color: white;
        }
    }
}
a{
    color: inherit;
}