.error-flash{
    @extend %flexCol;
    gap: 1rem;

    &-item{
        overflow: hidden;
        position: relative;
        @extend %flexCenter;
        @extend %rounded;
        padding: .8rem;
        font-size: 1rem;
        font-weight: 500;
        &::after{
            position: absolute;
            content: '';
            inset: 0;
            opacity: .09;
        }
        &.notice{
            &::after{
                background-color: $notice;
            }
            color: $notice;
        }
        &.info{
            &::after{
                background-color: $info;
            }
            color: $info;
        }
        &.success{
            &::after{
                background-color: $success;
            }
            color: $success;
        }
        &.warning{
            &::after{
                background-color: $warning;
            }
            color: $warning;
        }
        &.error{
            &::after{
                background-color: $danger;
            }
            color: $danger;
        }
    }
}