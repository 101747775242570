.searchTags{
    &__item{
        display: inline-flex;
        padding: .5rem .8rem;
        font-size: .9rem;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        z-index: 2;
        @extend %rounded;
        @apply transition-all;
        &::after{
            content: "";
            z-index: -1;
            position: absolute;
            inset: 0;
            opacity: 0;
            @apply transition-all;
        }
    }

    input[type="checkbox"]{
        &:disabled  ~ label{
            cursor: not-allowed;
        }
        &:checked  ~ label{
            color: white;
            &::after{
                opacity: 1;
            }
        }
    }
}

