@use "sass:math";
@use "sass:color";
@use "sass:map";
@use "sass:string";
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import "../../node_modules/@innovdata/uikits/src/sass/default.scss";

@import "abstracts";
@import "base";
@import "utils";

*{
    box-sizing: border-box;
}
html{
    font-size: $font-base;
    scroll-behavior: smooth;
}
body{
    color: $dark;
    background-color: $light-2;
    font-family: var(--font-family1);
    min-height: 100vh;
    display: flex;
}

@import "./components";
@import "./layout";
@import "./pages";
@import "./libs";

.h-headerBar{
    height: 50px;
}

.is-draggable{
    &,
    *:not(button){
        cursor: grab;
    }
}


.is-dragging{
    &,
    *:not(button){
        cursor: grabbing;
    }
}