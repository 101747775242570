.accordions{
    @extend %flexCol;
    &__head{
        transition-duration: 200ms !important;
        @extend %transition;
        background-color: $light-2;
        &,
        .accordions__title{
            color: $dark;
        }
        &:hover{
            background-color: $light-4;
        }
        &-outline{
            background-color: transparent;
            border: 1px solid $light-4;
            &:hover{
                background-color: $light-2;
            }
        }
    }
    &__content{
        opacity: 1 !important;
        transition-duration: 300ms !important;
        & > *{
            color: $dark;
        }
    }

    &__items:first-child{
        .accordions__head{
            border-top-left-radius: $rounded;
            border-top-right-radius: $rounded;
        }
    }
    &__items:last-child{
        .accordions__head{
            border-bottom-left-radius: $rounded;
            border-bottom-right-radius: $rounded;
        }
    }
}

.accordions{
    .accordions__items.active{
        .accordions__head{
            background-color: $light-3;
            color: $dark;
            &-outline{
                background-color: $light-3;
            }
        }
    }

    .accordions__items.is-disabled{
        .hs-accordion-toggle{
            font-style: italic;
        }
        background-color: $light-1;
        &-outline{
            background-color: $light-3;
        }
    }
}