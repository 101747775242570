.dropdown{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    &__name,
    .hs-dropdown-toggle{
        // all: unset;
        cursor: pointer;
        display: inline-flex ;
        // @extend .links;
    }
    &__icon{
        @extend %transition;
    }

    &__content{
        transform: none !important;
        @extend %transition;
        @extend %rounded;
        @apply shadow-sm;
        position: absolute;
        top: 110%;
        left: 0;
        width: 300px;
        @extend %flexCol;
        background-color: white;
        color: $dark;
        z-index: 1;
        overflow: auto;
        padding: 0 !important;
        &.start-right{
            left: unset;
            right: 0;
        }
        max-height: 0;
    }

    &.is-open,
    &.open{
        .dropdown__content{
            opacity: 1;
        }
        .dropdown__icon{
            transform: rotate(180deg);
        }
        .dropdown__content{
            max-height: 500px;
        }
    }
}