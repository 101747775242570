button{
    border: none;
    background-color: transparent;
    // appearance: none;
    cursor: pointer;
    &:disabled{
        cursor: default;
        opacity: .8;
    }
}
button,.btn{
    outline-width: 0 !important;
    outline-style: solid;
    outline-color: transparent;
}
.btn{
    line-height: 1rem;
    &:hover,&:focus,&.is-active{
        outline-offset: 0px !important;
    }
    min-height: 45px;
    padding: .75rem;
    border: 0;
    @extend %rounded;
    transition-duration: 200ms;

    &-light{
        background-color: transparent;
        outline-color: transparent;
        color: $dark;
        &:hover,&:focus,&.is-active{
            background-color: $light;
            outline-color: $light;
        }
    }
    &-primary{
        background-color: $primary;
        outline-color: $primary;
        color: white;
        &:hover,&:focus,&.is-active{
            background-color: $primary-4;
            outline-color: $primary-4;
        }
    }
    &-secondary{
        background-color: $secondary;
        outline-color: $secondary;
        color: white;
        &:hover,&:focus,&.is-active{
            background-color: $secondary-4;
            outline-color: $secondary-4;
        }
    }
    &-tertiary{
        background-color: $tertiary;
        outline-color: $tertiary;
        color: white;
        &:hover,&:focus,&.is-active{
            background-color: $tertiary-4;
            outline-color: $tertiary-4;
        }
    }
    &-warning{
        background-color: $warning;
        outline-color: $warning;
        color: $dark;
    }
    &-danger{
        background-color: $danger;
        outline-color: $danger;
        color: white;
    }
    &-info{
        background-color: $info;
        outline-color: $info;
        color: white;
    }
    &-notice{
        background-color: $notice;
        outline-color: $notice;
        color: white;
    }
    &-success{
        background-color: $success;
        outline-color: $success;
        color: white;
    }
    &-dark{
        background-color: $dark;
        outline-color: $dark;
        color: white;
    }
    &-white{
        background-color: white;
        outline-color: white;
        color: $dark;
    }

    &-danger,
    &-warning,
    &-notice,
    &-info,
    &-success,
    &-dark{
        &:hover,&:focus,&.is-active{
            opacity: .9;
        }
    }
}
.btn-sm{
    padding: .5rem;
    line-height: .9rem;
    font-size: .9rem;
    min-height: unset;
}

.btn-xs{
    font-size: .7rem;
    padding: .4rem;
    line-height: .8rem;
    min-height: unset;
}