.modal{
    &__container{
        &--overlay{
            @apply max-w-screen-sm md:max-w-screen-md pb-16 px-8;
        }
    }
    &__content{
        background-color: white;
        border-radius: $rounded !important;
        @apply shadow-md;
        &--close{
            top: 1rem;
            right: 1rem;
        }
        &--head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &--head,
        &--main{
            padding: .5rem;
            @media screen and (min-width: 768px){
                padding: 1rem !important;
            }
        }
        
    }
}

.modal-updateCustomer{
    position: absolute;
    z-index: 100;
    width: 50%;
    height: 50%;
    background-color: white;
    top: 0;
    left: 0;
}