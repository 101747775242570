:root{
    @each $mapColors, $nameMapColors in $colorsVariantes {
        @each $nameColor, $color in $nameMapColors {
            --#{$mapColors}-#{$nameColor}: #{$color};
        }
    }

    @each $nameFont, $font  in $fontsVariantes {
        --font-family#{$nameFont}: #{$font};
    }
    
    --color-dark: #191A35;
    --color-light: #fafafb;
    --color-gray: #F5F6FB;
    --color-info: #3ABFF8;
    --color-warning: #FBBD23;
    --color-danger: #f44444;
    --color-notice: #3ABFF8;
    --color-success: #36D399;
    --alert-info: var(--color-info);
    --alert-success: var(--color-success);
    --alert-warning: var(--color-warning);
    --alert-danger: var(--color-danger);
    --alert-dark: var(--color-dark);
}