.previewMedias{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;


    &__item{
        @apply bg-light-100;
        // cursor: pointer;
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;
        @apply rounded-sm;

        .actions{
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: flex-end;
            padding: 0.5rem;
            gap: 2rem;
            align-items: center;
        }

        picture{
            cursor: pointer;
            width: 200px;
            height: 200px;
            max-width: 100%;
            max-height: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &:first-child{
            &::after{
                content: 'Image de couverture';
                font-size: .9rem;
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                bottom: 0;
                @apply bg-white bg-opacity-70 text-dark;
                text-align: center;
                padding: .3rem .1rem;
            }
        }
    }

    &__item-xs{
        @extend .previewMedias__item;
        width: 100px;
        height: 100px;
    }
}

.previewMedias.is-list{
    .previewMedias__item{
        &:first-child{
            &::after{
                display: none;
            }
        }
    }
}