.cards{
    @extend %rounded;
    @extend %transition;
    // overflow: hidden;
    background-color: white;
    color: $dark;
    gap: 0;
    border-width: 0;
    border-style: solid;
    &__container,
    &__title{
        padding: 1rem;
    }
    &__title{
        @apply text-lg truncate;

    }
    &-light{
        .cards__title{
            background-color: $light-2;
        }
        background-color: $light-1;
        color: $dark;
        border-color: $light-2;
    }
    &-primary{
        .cards__title{
            background-color: $primary-2;
        }
        background-color: $primary-1;
        color: white;
        border-color: $primary-2;
    }
    &-secondary{
        .cards__title{
            background-color: $secondary-2;
        }
        background-color: $secondary-1;
        color: white;
        border-color: $secondary-2;
    }
    &-tertiary{
        .cards__title{
            background-color: $tertiary-2;
        }
        background-color: $tertiary-1;
        color: $dark;
        border-color: $tertiary-2;
    }
    &-dark{
        .cards__title{
            background-color: $dark;
            border-bottom: 1px solid white;
        }
        background-color: $dark;
        color: white;
        border-color: $dark;
    }
    &-white{
        .cards__title{
            background-color: $light;
        }
        background-color: white;
        color: $dark;
        border-color: $light;
    }

    
    &-outline{
        background-color: transparent;
        border-width: 2px;
    }
}

a.cards{
    &:hover,&:focus,&.is-active{
        @apply shadow-sm;
    }
}