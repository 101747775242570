.alert{
    @extend %rounded;
    font-weight: 400;
    flex-wrap: nowrap;
    align-items: flex-start;

    &--info{
        @apply bg-info-100 text-info-800;
    }
    &--notice{
        @apply bg-notice-100 text-notice-800;
        // color: white;
    }
    &--success{
        @apply bg-success-100 text-success-800;
        // @apply bg-alerts-success;
        // color: white;
    }

    &--warning{
        @apply bg-warning-100 text-warning-800;
        // @apply bg-alerts-warning;
    }
    &--danger,
    &--error{
        @apply bg-danger-100 text-danger-800;
        // @apply bg-alerts-danger;
        // color: white;
    }
}