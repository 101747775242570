// :root{
//     --dt-row-selected: 13, 110, 253;
// }

.dataTables_wrapper {
  
}

table.dataTable{
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    tbody{
        tr{
            &.even{
                background-color: var(--light-100);
            }
            &.odd{
                // background-color: var(--light-200);
            }

            &.selected{
                background-color: var(--light-300);
                > *{
                    box-shadow: unset !important;
                    color: unset !important;
                }
            }
        }
    }
}
table.dataTable tbody tr.selected a{
    &.btn-primary,
    &.btn-secondary,
    &.btn-success
    &.btn-danger
    &.tertiary{
        color: white !important;
    }
}
body{
    .dataTables_wrapper {
        min-height: 150px;
        .dataTables_paginate{
            .paginate_button{
                @extend .btn;
                min-height: 30px;
                border: 0;
                &:hover,&:focus,&.is-active{
                    border: 0;
                    background: var(--color1-500);
                }
            }

            .paginate_button.current{
                border: 0;
                color: white !important;
                background: var(--color1-500);
                &:hover,&:focus,&.is-active{
                    border: 0;
                    color: white !important;
                    background: var(--color1-500);
                }
            }
        }

        .dataTables_processing{
            background: rgba($color: #ffffff, $alpha: .9);
            left: 0;
            right: 0;
            top: 0;
            margin: unset !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .dataTables_scrollHeadInner{
        width: 100% !important;
    }
}
