

.swal2-radio{
    margin: 1rem 0;
    label{
        input[type="radio"]{
            visibility: hidden;
        }

        input[type="radio"] + span{
            cursor: pointer;
            border: 1px solid $light-4;
            border-radius: $rounded;
            padding: 0.5rem 1rem;
            @apply transition-all
        }

        span:hover{
            border: $light-6;
        }

        input[type="radio"]:checked + span{
            background-color: $primary;
            color: white;
        }
    }
}