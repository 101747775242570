.table{
    @apply text-base;
    border-collapse: collapse;
    &--body{
        background-color: transparent;
        border-bottom: 1px solid $light-3;
        &.border-b-0{
            border-bottom: 0px;
        }
        &__item{
            font-weight: 400;
            text-transform: initial;
        }
    }
    &--head{
        background-color: transparent;
        border-bottom: 1px solid $primary-3;
        &__item{
            font-weight: 600;
        }
    }
    &--body__item,
    &--head__item{
        @apply text-sm;
        padding: .5rem;
        white-space: normal;
    }
    tbody tr:last-child{
        border-bottom: none;
        th, td{
            border-bottom: none;
        }
    }

}