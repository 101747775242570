.loader-element {
    width: 35px;
    height: 35px;
    border: 5px solid var(--color1-500);
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;

    &.hidden {
        display: none !important;
    }
    &:not(.hidden) {
        display: inline-block;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 